<template>
    <div class="authSuccess">
        <p class="infoText" style="position: relative; margin: auto">您已完成支付宝授权，关闭当前页面后确定授权结果</p>
    </div>
</template>

<script>
export default {
    name: 'MenuFinanceAliPayAuthSuccess',
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style scoped>
.authSuccess {
    background-image: url('../../../assets/login/authResult.png');
    background-repeat: no-repeat;
    height: 100%;
    width: 100%;
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    text-align: center;
    background-size: cover;
    overflow: hidden;
}
.infoText {
    position: fixed;
    top: 50%;
    left: 1%;
    user-select: none;
    cursor: pointer;
}
</style>
